import { gql } from '@apollo/client'

/**
 * Get session fields
 * @internal
 */
export const sessionFieldsQuery: string = `
  id
  customerUid
  configuration {
    filters {
      attributes
      promotions {
        active
      }
      categories {
        active
      }
      brands {
        active
      }
    }
    stock {
      enabled
      stockThreshold
      key
      threshold {
        lowWarning {
          id
          warningMessage
          quantity
          enabled
        }
        criticalLowWarning {
          id
          warningMessage
          quantity
          enabled
        }
      }
    }
    promotions {
      enabled
      maximumActive
      autoAdd
      discountFromStock
      disableFreeGoodsAsDiscount
    }
    i18n {
      language
      currencyFormat
      currencySymbol
      currencyCode
      country
    }
    workflow
    checkoutRules {
      cartConditionPendingMinAmount {
        isActive
        message
        quantity
      }
      cartConditionPendingMaxAmount {
        quantity
        message
        isActive
      }
      cartConditionPendingMinQty {
        quantity
        message
        isActive
      }
      cartConditionPendingMaxQty {
        quantity
        message
        isActive
      }
      cartConditionPendingUserValidation {
        message
        sku
        attribute
        isActive
      }
      cartWarningCheckReturnables {
        message
        sku
        attribute
        isActive
      }
    }
    sessionTtl
    groupers {
      cart {
        session
        active
        product
      }
      order {
        active
        session
        product
      }
    }
    recommendedProducts {
      prefillCart
      suggestedItems
      frequentlyBoughtTogether
    }
    showRecommendations
    notifications {
      type
    }
  }
  workflow {
    name
    channel
    channelUid
    userUid
  }
  banners {
    id
    imagesURL
    customerType
    reference
    type
    isActive
    priority
    startDate
    expirationDate
  }
  customer{
    email
    phoneNumber
    code
  }
  startedAt
  updatedAt
  status
  finishedAt
  createdAt
`

/**
 * Get's the string for `session` query.
 * This query gets all existing items in the current session.
 * @internal
 */
const getSessionQuery = gql`
  query session(
    $storefrontName: String!
    $sessionUid: ID!
  ) {
    session(storefrontName: $storefrontName, id: $sessionUid) {
      ${sessionFieldsQuery}
    }
  }
`

export default getSessionQuery
