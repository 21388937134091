import { IActionIconButton } from '@engyalo/delivery-ui-components/lib/interfaces/actions'
import {
  CinnamonActionNames,
  CinnamonPaths,
  DefaultModalContainerNames,
} from '../../defaultConstants'

export const ButtonBack: IActionIconButton = {
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.GO_BACK_DYNAMIC,
      },
    ],
  } as any,
}

export const ButtonBackHome: IActionIconButton = {
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.GO_BACK,
        args: {
          path: CinnamonPaths.HOME,
        },
      },
    ],
  } as any,
}

export const ButtonSearch: IActionIconButton = {
  icon: 'MdSearch',
  'data-testid': 'search',
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.SEARCH_PRODUCT,
        },
      },
    ],
  } as any,
}

export const ButtonCart: IActionIconButton = {
  icon: 'MdOutlineShoppingCart',
  'data-testid': 'cart',
  badge: {
    value: '{{cart.itemsTotal}}',
  },
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.PUSH_VIEW,
        args: {
          path: CinnamonPaths.CART,
        },
      },
    ],
  } as any,
}

export const CategoryNavBack: IActionIconButton = {
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.CONDITIONAL_PUSH_VIEW,
        args: {
          path: CinnamonPaths.CATEGORIES,
          values: [
            {
              value1: '{{default.config.options.usePaginatedCategories}}',
              value2: true,
            },
          ],
        },
      },
      {
        name: CinnamonActionNames.CONDITIONAL_PUSH_VIEW,
        args: {
          path: CinnamonPaths.CATEGORIES_NAVIGATION,
          values: [
            {
              value1: '{{default.config.options.usePaginatedCategories}}',
              value2: false,
            },
          ],
        },
      },
    ],
  } as any,
}
