import {
  SessionNotifications,
  SessionNotificationsArray,
} from '../interfaces/config'

const castSessionNotifications = (
  notifications: SessionNotificationsArray,
): Record<SessionNotifications, boolean> => {
  const defaultValues = {
    [SessionNotifications.STOCK_AVAILABLE]: false,
    [SessionNotifications.AUTHENTICATION_NEEDED]: false,
    [SessionNotifications.SESSION_CREATED]: false,
    [SessionNotifications.ORDER_REMINDER]: false,
    [SessionNotifications.ABANDONED_CART]: false,
  }
  const castedValues = notifications.reduce(
    (acc, { type }) => ({ ...acc, [type]: true }),
    {} as Record<SessionNotifications, boolean>,
  )
  return { ...defaultValues, ...castedValues }
}

export default castSessionNotifications
