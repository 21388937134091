import { CatalogueListProps } from '@engyalo/delivery-ui-components/lib/ui/components/CatalogueList'
import { DefaultProps } from '../../../interfaces'
import {
  CinnamonActionNames,
  CinnamonPaths,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'
import { ItemCardCatalog } from './ItemCard'
import deepMerge from '../../../utils/deepMerge'

export const CatalogueListBase: DefaultProps<CatalogueListProps> = {
  catalogue: '{{catalog.categories}}' as any,
  isFetchingMore: '{{catalog.isFetchingMore}}' as any,
  loadingMoreElementsText: TextLibrary.LOADING_MORE_ELEMENTS_TEXT,
  itemCard: {
    ...ItemCardCatalog,
    maxQtyMessage: TextLibrary.CART_MAX_QTY_ALERT,
    image: {
      ...ItemCardCatalog.image,
      dimensions: '{{globals.imageConfig.imageSizes.catalogueImage}}' as any,
    },
  },
  layout: '{{globals.catalog.layout}}' as any,
  styles: {
    '& .HeadingWrapper': {
      paddingTop: '4px',
    },
    '& .itemCard-warning-wrapper': {
      padding: '10px',
      alignItems: 'flex-start',
    },
  },
}

export const CatalogueListDefault = deepMerge(CatalogueListBase, {
  categoryEnterActions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATALOG_UPDATE_CATEGORY,
        args: {
          category: '{{this.newValue}}',
        },
      },
      {
        name: TemplateActionNames.CATALOG_UPDATE_CATEGORY_CATALOG,
        args: {
          category: '{{this.newValue}}',
        },
      },
    ],
  } as any,
  runOnLoad: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.CONDITIONAL_PUSH_VIEW,
        args: {
          path: CinnamonPaths.CATEGORIES,
          values: [
            {
              value1: '{{default.config.options.usePaginatedCategories}}',
              value2: true,
            },
          ],
        },
      },
    ],
  },
})

export const CatalogueListCategoriesNav = deepMerge(CatalogueListBase, {
  catalogue: '{{categoriesNav.categories}}' as any,
  isFetchingMore: '{{categoriesNav.fetchingMore}}' as any,
  status: '{{categoriesNav.categoryStatus}}' as any,
  runOnLoad: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS,
      },
    ],
  } as any,
  viewObserver: {
    showLoader: false,
    entryActions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS,
          args: {
            categoryName: '{{this.data.category}}',
          },
        },
      ],
    } as any,
  },
})

export const OptionalCatalogueListCategoriesNav = deepMerge(
  CatalogueListCategoriesNav,
  {
    hidden: '{{config.options.hideSubCategoriesProducts}}' as any,
  },
)
